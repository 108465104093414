import {
  IsNotEmpty,
  IsOptional,
  IsDate,
  IsBoolean,
  IsIn,
  ValidateIf,
} from "class-validator";

import { Constants } from "@river/constants";

import { IAdapterFolder } from "./adapter-folder.interface";

export class AdapterFolderDto
  implements Omit<IAdapterFolder, "_id" | "status">
{
  @IsNotEmpty()
  folder: string;

  @IsOptional()
  description?: string;

  @IsNotEmpty()
  @IsIn([Constants.folder_type.project, Constants.folder_type.schedule])
  folder_type: string = Constants.folder_type.project;

  @IsNotEmpty()
  @IsDate()
  start_date: Date;

  @IsNotEmpty()
  @IsDate()
  end_date: Date;

  @IsOptional()
  calendar_id?: string | null;

  @IsBoolean()
  use_avail_to_schedule_assignments: boolean = false;

  /** Other ERP specific attributes */
  [s: string]: any;
}
