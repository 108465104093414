import { useContext } from "react";
import { RiverFormContext } from "../context";
import { useValidation, } from "../components";
export const useFormField = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const formContext = useContext(RiverFormContext);
    const form = formContext === null || formContext === void 0 ? void 0 : formContext.form;
    const validation = useValidation();
    const id = props.id;
    let validationErrors;
    let label = "";
    let value = "";
    let checked;
    let disabled;
    let isDtoField = false;
    let isStandaloneField = false;
    let radioOptions = [];
    validationErrors = form === null || form === void 0 ? void 0 : form.validationErrors.fields[id];
    if (props.validationErrors) {
        validationErrors = props.validationErrors;
    }
    isDtoField = !!form && Object.keys(form.dto).includes(id);
    isStandaloneField = !!form && Object.keys(form.standalone).includes(id);
    if (typeof props.value !== "undefined") {
        value = (_a = props.value) !== null && _a !== void 0 ? _a : "";
    }
    else if (typeof props.checked !== "undefined") {
        checked = props.checked;
    }
    else if (isDtoField) {
        value = (_c = (_b = form === null || form === void 0 ? void 0 : form.dto) === null || _b === void 0 ? void 0 : _b[id]) !== null && _c !== void 0 ? _c : "";
        checked = Boolean(value);
    }
    else if (isStandaloneField) {
        value = (_e = (_d = form === null || form === void 0 ? void 0 : form.standalone) === null || _d === void 0 ? void 0 : _d[id]) !== null && _e !== void 0 ? _e : "";
        checked = Boolean(value);
    }
    label = (_f = props.label) !== null && _f !== void 0 ? _f : (_g = form === null || form === void 0 ? void 0 : form.labels) === null || _g === void 0 ? void 0 : _g[id];
    disabled =
        (_h = props.disabled) !== null && _h !== void 0 ? _h : ((form === null || form === void 0 ? void 0 : form.isFieldReadOnly(props.id)) || (form === null || form === void 0 ? void 0 : form.isReadOnly));
    if (props.radioOptionIds) {
        radioOptions = props.radioOptionIds.map((id) => {
            var _a;
            return { id, label: (_a = form === null || form === void 0 ? void 0 : form.labels) === null || _a === void 0 ? void 0 : _a[id] };
        });
    }
    const onChange = (event) => {
        var _a, _b;
        const value = ((_a = event.currentTarget) === null || _a === void 0 ? void 0 : _a.value) || ((_b = event.target) === null || _b === void 0 ? void 0 : _b.value);
        if (props.onChange) {
            props.onChange(value);
        }
        if (props.onChangeEvent) {
            props.onChangeEvent(event);
        }
        else if (isDtoField) {
            form === null || form === void 0 ? void 0 : form.onPropertyChange(event);
        }
        else if (isStandaloneField) {
            form === null || form === void 0 ? void 0 : form.onStandalonePropertyChange()(event);
        }
    };
    let hasErrors = false;
    let helperText = "";
    if (!disabled) {
        hasErrors = props.error || !!((_j = validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.errors) === null || _j === void 0 ? void 0 : _j.length);
        helperText =
            props.helperText ||
                validation.getFieldValidationMessage(validationErrors);
    }
    return {
        form,
        id,
        label,
        radioOptions,
        value,
        checked,
        disabled,
        isDtoField,
        isStandaloneField,
        validationErrors,
        onChange,
        hasErrors,
        helperText,
    };
};
