import { IsNotEmpty, IsArray } from "class-validator";
import { IAdapterAttachmentHierarchyAction } from "./adapter-attachment-hierarchy-action.interface";

export class AdapterAttachmentHierarchyActionDto
  implements IAdapterAttachmentHierarchyAction
{
  @IsNotEmpty()
  entity_name: string = "workorder";

  @IsArray()
  entity_ids: string[];
}
