//import * as dotenv from "dotenv";
//import * as dotenvExpand from "dotenv-expand";

const isBrowser =
  typeof window !== "undefined" && typeof window.document !== "undefined";

// (() => {
//   // it makes sense to have dotenv configred only for server processes
//   // (it does not mean anything for the client scripts)
//   if (__dirname !== "/") {
//     let cfg = dotenv.config({
//       path: __dirname + "/../../../../.env.development",
//     });

//     dotenvExpand.expand(cfg);
//   }
// })();

export const Config = {
  // consider this option to be dynamically configurable
  system: {
    default_query_fetch_size: 200,
    // this cannot be changed, otherwise encrypted values cannot be recovered
    mongodb_encryption_secret: process.env.MONGODB_ENCRYPTION_SECRET,
  },
  environment: process.env.ENVIRONMENT || "unknown",

  json_logging: process.env.JSON_LOGGING == "true",

  /** Config for DataDog. */
  data_dog: {
    service: process.env.DD_SERVICE,
    api_key: process.env.DATADOG_API_KEY,
    use_agentless_logger: process.env.DATADOG_USE_AGENTLESS_LOGGER === "true",
  },

  auth: {
    platform_system_user_id: process.env.RIVER_SYSTEM_USER_ID,
    platform_system_user: process.env.RIVER_SYSTEM_USER,
    platform_system_password: process.env.RIVER_SYSTEM_PASSWORD,

    // JWT siging options for access token
    access_token_options: {
      secret: process.env.RIVER_ACCESS_TOKEN_SECRET,
      //signOptions: {
      expiresIn: process.env.RIVER_ACCESS_TOKEN_DURATION || "5m",
      //}
    },

    // JWT siging options for refresh token
    refresh_token_options: {
      secret: process.env.RIVER_REFRESH_TOKEN_SECRET,
      //signOptions: {
      expiresIn: process.env.RIVER_REFRESH_TOKEN_DURATION || "60m",
      //}
    },

    captcha_secret_key: process.env.RIVER_CLOUDFLARE_TURNSTILE_SECRET_KEY,
  },

  // Redis Server
  cache_server_options: {
    url: process.env.RIVER_API_REDIS_URL || "redis://localhost:6379",
    password: process.env.RIVER_API_REDIS_PASSWORD,
    // host: process.env.RIVER_API_REDIS_HOST || "localhost",
    // port: parseInt(process.env.RIVER_API_REDIS_PORT || "6379"),
  },

  platform_api: {
    db_url: process.env.PLATFORM_API_DB_URL || "",
    port: parseInt(process.env.PLATFORM_API_PORT || "3001"),

    payload_max_size: process.env.PLATFORM_API_PAYLOAD_MAX_SIZE || "1mb",
    log_level: process.env.PLATFORM_API_LOG_LEVEL || "error",
  },

  adapter_api: {
    //db_url_template: process.env.ADAPTER_API_DB_URL_TEMPLATE || '',
    port: parseInt(process.env.ADAPTER_API_PORT || "3002"),
    ssl: process.env.ADAPTER_API_SSL === "true",
    cert_file: process.env.ADAPTER_API_CERT_FILE,
    key_file: process.env.ADAPTER_API_CERT_KEY_FILE,

    saml2_cert_file: process.env.ADAPTER_API_SAML2_CERT_FILE,
    saml2_key_file: process.env.ADAPTER_API_SAML2_CERT_KEY_FILE,

    payload_max_size: process.env.ADAPTER_API_PAYLOAD_MAX_SIZE || "10mb",

    auto_schedule_service_url:
      process.env.ADAPTER_API_AUTO_SCHEDULE_SERVICE_URL,

    log_level: process.env.ADAPTER_API_LOG_LEVEL || "error",
  },

  adapter_ms: {
    transport_options: {
      // TCP = 0,
      // REDIS = 1,
      // NATS = 2,
      // MQTT = 3,
      // GRPC = 4,
      // RMQ = 5,
      // KAFKA = 6
      //transport: 0, //TCP
      transport: 5,
      // options: {
      //   host: process.env.DATA_DICT_CACHE_API_URL,
      //   port: parseInt(process.env.DATA_DICT_CACHE_API_PORT || '3000')
      // }
      options: {
        urls: [process.env.RIVER_API_RMQ_URL || "amqp://localhost:5672"],
        queue: process.env.ADAPTER_MS_QUEUE_NAME || "adapter",
        prefetchCount: parseInt(process.env.ADAPTER_MS_PREFETCH_COUNT || "20"),
        queueOptions: {
          durable: false,
        },
      },
    },
    log_level: process.env.ADAPTER_MS_LOG_LEVEL || "error",
  },

  integration_api: {
    port: process.env.INTEGRATION_API_PORT || "3003",
  },

  tunnel_server_api: {
    protocol: process.env.RIVER_TUNNEL_SERVER_PROTOCOL || "http://",
    port: parseInt(process.env.RIVER_TUNNEL_SERVER_PORT || "3004"),
    domain: process.env.RIVER_TUNNEL_SERVER_DOMAIN, //it should include an external port
    client_port_range_min: parseInt(
      process.env.RIVER_TUNNEL_CLIENT_PORT_RANGE_MIN || "10000"
    ),
    client_port_range_max: parseInt(
      process.env.RIVER_TUNNEL_CLIENT_PORT_RANGE_MAX || "11000"
    ),

    ssl: process.env.RIVER_TUNNEL_SERVER_SSL === "true",
    ca_file: process.env.RIVER_TUNNEL_SERVER_CA_FILE,
    cert_file: process.env.RIVER_TUNNEL_SERVER_CERT_FILE,
    key_file: process.env.RIVER_TUNNEL_SERVER_CERT_KEY_FILE,
    grace_timeout_ms: process.env.RIVER_TUNNEL_GRACE_TIMEOUT_MS
      ? Number.parseInt(process.env.RIVER_TUNNEL_GRACE_TIMEOUT_MS)
      : 1500,
    log_level: process.env.RIVER_TUNNEL_SERVER_API_LOG_LEVEL || "error",
  },

  poller_api: {
    log_level: process.env.RIVER_POLLER_API_LOG_LEVEL || "error",
  },

  aws_s3_file_storage: {
    client_options: {
      accessKeyId: process.env.RIVER_AWS_S3_ACCESS_KEY || "",
      secretAccessKey: process.env.RIVER_AWS_S3_SECRET_KEY || "",
      region: process.env.RIVER_AWS_S3_REGION || "",
    },
    bucket: process.env.RIVER_AWS_S3_BUCKET || "",
    bucket_help_files: process.env.RIVER_AWS_S3_BUCKET_HELP_FILES || "",
  },

  aws_ses: {
    client_options: {
      credentials: {
        accessKeyId: process.env.RIVER_AWS_SES_ACCESS_KEY || "",
        secretAccessKey: process.env.RIVER_AWS_SES_SECRET_KEY || "",
      },
      region: process.env.RIVER_AWS_SES_REGION || "",
    },
    from_address: process.env.RIVER_AWS_SES_DEFAULT_FROM_ADDRESS || "",
  },

  attachments_download_ms: {
    transport_options: {
      // TCP = 0,
      // REDIS = 1,
      // NATS = 2,
      // MQTT = 3,
      // GRPC = 4,
      // RMQ = 5,
      // KAFKA = 6
      // transport: 0, //TCP
      transport: 5,
      options: {
        urls: [process.env.RIVER_API_RMQ_URL || "amqp://localhost:5672"],
        queue:
          process.env.ADAPTER_ATTACHMENTS_DOWNLOAD_MS_QUEUE_NAME ||
          "attachments_download",
        prefetchCount: parseInt(
          process.env.ADAPTER_ATTACHMENTS_DOWNLOAD_MS_PREFETCH_COUNT || "1"
        ),
        queueOptions: {
          durable: false,
          autoDelete: true,
        },
      },
    },
    log_level: process.env.ADAPTER_ATTACHMENTS_DOWNLOAD_MS_LOG_LEVEL || "error",
  },

  attachments_conversion_ms: {
    transport_options: {
      // TCP = 0,
      // REDIS = 1,
      // NATS = 2,
      // MQTT = 3,
      // GRPC = 4,
      // RMQ = 5,
      // KAFKA = 6
      // transport: 0, //TCP
      transport: 5,
      options: {
        urls: [process.env.RIVER_API_RMQ_URL || "amqp://localhost:5672"],
        queue:
          process.env.ADAPTER_ATTACHMENTS_CONVERSION_MS_QUEUE_NAME ||
          "attachments_conversion",
        prefetchCount: parseInt(
          process.env.ADAPTER_ATTACHMENTS_CONVERSION_MS_PREFETCH_COUNT || "1"
        ),
        queueOptions: {
          durable: false,
          autoDelete: true,
        },
      },
    },
    log_level:
      process.env.ADAPTER_ATTACHMENTS_CONVERSION_MS_LOG_LEVEL || "error",
  },

  attachments_conversion_status_ms: {
    transport_options: {
      // TCP = 0,
      // REDIS = 1,
      // NATS = 2,
      // MQTT = 3,
      // GRPC = 4,
      // RMQ = 5,
      // KAFKA = 6
      // transport: 0, //TCP
      transport: 5,
      options: {
        urls: [process.env.RIVER_API_RMQ_URL || "amqp://localhost:5672"],
        queue:
          process.env.ADAPTER_ATTACHMENTS_CONVERSION_STATUS_MS_QUEUE_NAME ||
          "attachments_conversion_status",
        queueOptions: {
          durable: false,
          autoDelete: true,
        },
      },
    },
    log_level:
      process.env.ADAPTER_ATTACHMENTS_CONVERSION_STATUS_MS_LOG_LEVEL || "error",
  },

  attachments_merge_ms: {
    transport_options: {
      // TCP = 0,
      // REDIS = 1,
      // NATS = 2,
      // MQTT = 3,
      // GRPC = 4,
      // RMQ = 5,
      // KAFKA = 6
      // transport: 0, //TCP
      transport: 5,
      options: {
        urls: [process.env.RIVER_API_RMQ_URL || "amqp://localhost:5672"],
        queue:
          process.env.ADAPTER_ATTACHMENTS_MERGE_MS_QUEUE_NAME ||
          "attachments_merge",
        prefetchCount: parseInt(
          process.env.ADAPTER_ATTACHMENTS_MERGE_MS_PREFETCH_COUNT || "1"
        ),
        queueOptions: {
          durable: false,
          autoDelete: true,
        },
      },
    },
  },

  attachments_merge_status_ms: {
    transport_options: {
      // TCP = 0,
      // REDIS = 1,
      // NATS = 2,
      // MQTT = 3,
      // GRPC = 4,
      // RMQ = 5,
      // KAFKA = 6
      // transport: 0, //TCP
      transport: 5,
      options: {
        urls: [process.env.RIVER_API_RMQ_URL || "amqp://localhost:5672"],
        queue:
          process.env.ADAPTER_ATTACHMENTS_MERGE_STATUS_MS_QUEUE_NAME ||
          "attachments_merge_status",
        queueOptions: {
          durable: false,
          autoDelete: true,
        },
      },
    },
    log_level:
      process.env.ADAPTER_ATTACHMENTS_MERGE_STATUS_MS_LOG_LEVEL || "error",
  },

  cron_dispatcher_ms: {
    /** The number of last runs to track for each cron task. This is used for debugging. */
    last_run_count: process.env.CRON_DISPATCHER_LAST_RUN_COUNT
      ? Number.parseInt(process.env.CRON_DISPATCHER_LAST_RUN_COUNT)
      : 10,
    transport_options: {
      // TCP = 0,
      // REDIS = 1,
      // NATS = 2,
      // MQTT = 3,
      // GRPC = 4,
      // RMQ = 5,
      // KAFKA = 6
      //transport: 0, //TCP
      transport: 5,
      // options: {
      //   host: process.env.DATA_DICT_CACHE_API_URL,
      //   port: parseInt(process.env.DATA_DICT_CACHE_API_PORT || '3000')
      // }
      options: {
        urls: [process.env.RIVER_API_RMQ_URL || "amqp://localhost:5672"],
        queue: process.env.CRON_DISPATCHER_MS_QUEUE_NAME || "cron_dispatcher",
        queueOptions: {
          durable: false,
          autoDelete: true,
        },
      },
    },
    log_level: process.env.CRON_DISPATCHER_MS_LOG_LEVEL || "error",
  },

  cron_manager_ms: {
    log_level: process.env.CRON_MANAGER_MS_LOG_LEVEL || "error",
  },

  // mailer_ms: {
  //   transport: {
  //     pool: true,
  //     host: process.env.RIVER_MAILER_SMTP_HOST,
  //     port: parseInt(process.env.RIVER_MAILER_SMTP_PORT || "587"),
  //     tls: { ciphers: "SSLv3" },
  //     secure: process.env.RIVER_MAILER_SMTP_SECURE === "true", // use SSL
  //     //logger: false,
  //     auth: {
  //       user: process.env.RIVER_MAILER_SMTP_USER,
  //       pass: process.env.RIVER_MAILER_SMTP_PASSWORD,
  //     },
  //     defaults: {
  //       from: process.env.RIVER_MAILER_SMTP_FROM,
  //     },
  //   },
  // },

  report_generate_ms: {
    transport_options: {
      // TCP = 0,
      // REDIS = 1,
      // NATS = 2,
      // MQTT = 3,
      // GRPC = 4,
      // RMQ = 5,
      // KAFKA = 6
      // transport: 0, //TCP
      transport: 5,
      options: {
        urls: [process.env.RIVER_API_RMQ_URL || "amqp://localhost:5672"],
        queue:
          process.env.ADAPTER_REPORT_GENERATE_MS_QUEUE_NAME ||
          "report_generate",
        prefetchCount: parseInt(
          process.env.ADAPTER_REPORT_GENERATE_MS_PREFETCH_COUNT || "1"
        ),
        queueOptions: {
          durable: false,
          autoDelete: true,
        },
      },
    },
    log_level: process.env.ADAPTER_REPORT_GENERATE_MS_LOG_LEVEL || "error",
  },

  mongodb_atlas: {
    app_services_base_url: "https://realm.mongodb.com/api/admin/v3.0",
  },

  web: {
    HOST: "0.0.0.0",
    REACT_APP_PLATFORM_API_URL: isBrowser
      ? eval("window._env_.REACT_APP_PLATFORM_API_URL")
      : process.env.REACT_APP_PLATFORM_API_URL,
    REACT_APP_PLATFORM_WEB_URL: isBrowser
      ? eval("window._env_.REACT_APP_PLATFORM_WEB_URL")
      : process.env.REACT_APP_PLATFORM_WEB_URL,
    // REACT_APP_ADAPTER_API_URL: isBrowser
    //   ? eval("window._env_.REACT_APP_ADAPTER_API_URL")
    //   : process.env.REACT_APP_ADAPTER_API_URL,
    REACT_APP_ADAPTER_API_DOMAIN: isBrowser
      ? eval("window._env_.REACT_APP_ADAPTER_API_DOMAIN")
      : process.env.REACT_APP_ADAPTER_API_DOMAIN,
    REACT_APP_ADAPTER_API_PROTOCOL: isBrowser
      ? eval("window._env_.REACT_APP_ADAPTER_API_PROTOCOL")
      : process.env.REACT_APP_ADAPTER_API_PROTOCOL,
    REACT_APP_ADAPTER_WEB_DOMAIN: isBrowser
      ? eval("window._env_.REACT_APP_ADAPTER_WEB_DOMAIN")
      : process.env.REACT_APP_ADAPTER_WEB_DOMAIN,

    REACT_APP_CAPTCHA_SITE_KEY: isBrowser
      ? eval("window._env_.REACT_APP_CLOUDFLARE_TURNSTILE_SITE_KEY")
      : process.env.REACT_APP_CLOUDFLARE_TURNSTILE_SITE_KEY,

    NODE_ENV: "development",
  },

  // integration_api_ms: {

  //   transport_options: {
  //     // TCP = 0,
  //     // REDIS = 1,
  //     // NATS = 2,
  //     // MQTT = 3,
  //     // GRPC = 4,
  //     // RMQ = 5,
  //     // KAFKA = 6
  //     name: 'INTEGRATION_SERVICE',
  //     transport: 5,
  //     options: {
  //       urls: [process.env.RIVER_API_RMQ_URL || 'amqp://localhost:5672'],
  //       queue: 'integration',
  //       queueOptions: {
  //         durable: false
  //       },
  //     },
  //   }
  // }
};

// ----------------
export const oauth2ReplyUrl = (subdomain: string): string => {
  return `${Config.web.REACT_APP_ADAPTER_API_PROTOCOL}${subdomain}.${Config.web.REACT_APP_ADAPTER_API_DOMAIN}/api/adapter/auth/oauth2-callback`;
};

// ----------------
export const saml2ReplyUrl = (subdomain: string): string => {
  return `${Config.web.REACT_APP_ADAPTER_API_PROTOCOL}${subdomain}.${Config.web.REACT_APP_ADAPTER_API_DOMAIN}/api/adapter/auth/saml2-callback`;
};
